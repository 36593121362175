import { Error } from '@/models/Error';
import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export interface PostIncident {
    id: string;
    type: string | number;
    data: {
        date: string;
        description: string;
    };
}
export interface PostIncidentsState {
    error: Error | null;
    incidents: PostIncident;
}
export const postIncidentsThunk = createAsyncThunk<
    { token: string },
    PostIncident,
    { rejectValue: Error }
>('device/postIncidents', async (params, thunkApi) => {
    return request({
        url: `/api/devices/${params.id}/tickets`,
        method: 'POST',
        data: { type: params.type, data: params.data },
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    })
        .then((res: ApiResponse) => {
            toast.success('La incidencia se ha enviado con exito');
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            toast.error('La incidencia no se ha enviado correctamente');
            return thunkApi.rejectWithValue(err.data);
        });
});

export const postIncidentsSlice = createSlice({
    name: 'incidents',
    initialState: { error: null } as PostIncidentsState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postIncidentsThunk.fulfilled, (state, { payload }) => {
            state.error = null;
        });
    }
});
