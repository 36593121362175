import * as styles from './NavBar.module.scss';
import UserIcon from '@/assets/icons/person-circle.svg';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { MyModal } from '../MyModal/MyModal';
import { useState } from 'react';
import Button from '../Button/Button';
import { ButtonSizes, ButtonVariants } from '../Button/Button.types';
import CloseIcon from '@/assets/icons/box-arrow-left.svg';
import { useAppDispatch } from '@/index';
import { authActions } from '@/store/auth';
import accessToken from '@/utils/helpers/accessToken.helper';
import refreshToken from '@/utils/helpers/refreshToken.helper';

const NavBar = () => {
    const location = useLocation();
    const isIncidence = location.pathname.includes('incidents');
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const appDispatch = useAppDispatch();
    const handleLogout = () => {
        appDispatch(authActions.logout());
        accessToken.remove();
        refreshToken.remove();
    };
    return (
        <>
            <div className={styles.containerNav}>
                <div className={styles.header}>
                    <div className={styles.containerLogo}>
                        <img
                            className={styles.logo}
                            src={require('./../../assets/images/logo.png')}
                            alt=""
                        />

                        <div className={styles.inamLogo}>
                            <img
                                className={styles.inamLogo__icon}
                                src={require('./../../assets/images/inam-logo1.png')}
                            />
                            <img
                                className={styles.inamLogo__text}
                                src={require('./../../assets/images/inam-logo2.png')}
                            />
                        </div>
                    </div>

                    <div
                        className={styles.user}
                        onClick={() => {
                            setOpenModal(true);
                        }}
                    >
                        <div className={styles.text}>Usuario</div>

                        <UserIcon className={styles.icon} />
                    </div>
                </div>
                <div className={styles.nav}>
                    <button
                        className={classNames(styles.option, !isIncidence && styles.option__active)}
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        Datos
                    </button>
                    <div className={styles.vl} />
                    <button
                        className={classNames(styles.option, isIncidence && styles.option__active)}
                        onClick={() => {
                            navigate('/incidents');
                        }}
                    >
                        Incidencias
                    </button>
                </div>
            </div>
            <MyModal
                isOpen={openModal}
                handleClose={() => {
                    setOpenModal(false);
                }}
                title={'Deseas Salir?'}
            >
                <div className={styles.modalContent}>
                    <Button
                        variant={ButtonVariants.TERTIARY}
                        size={ButtonSizes.ADAPTABLE}
                        onClick={handleLogout}
                    >
                        <CloseIcon className={styles.modalContent__icon} />
                        Cerrar Sesión
                    </Button>
                </div>
            </MyModal>
        </>
    );
};

export default NavBar;
