.container {
    width: 100%;
    height: 100%;
    max-width: 900px;

    .content {
        margin: 1em;
        min-height: 80%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .title {
            font-size: larger;
            color: var(--color-primary);
            width: 100%;
            margin-top: 10px;
        }
        .inputDate {
            height: 26;
            width: auto;
            padding: 7px;
            border: 1px solid var(--color-gray);
            border-radius: 5px;
            color: gray;
        }

        .textArea {
            width: auto;
            height: 130px;
            border: 1px solid var(--color-gray);
            border-radius: 5px;
            padding: 10px;
            resize: none;
        }

        .textArea::placeholder {
            font-size: 12px;
            margin: 10px;
            padding: 10px;
        }

        .textArea:focus {
            outline: none;
            border: 1px solid var(--color-primary);
        }
    }
    .buttonsContainer {
        min-height: 200px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .buttons {
        width: 92%;
        max-width: 900px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        .button {
            width: 250px;
        }
    }
}

.modalContent {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    .modalTexts {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        gap: 10px;
        .modalText {
            font-size: large;
            color: var(--color-primary);
        }
    }
    .modalButtons {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
        .modalButton {
            width: 70%;
            height: 50px;
        }
    }
}
