import { useField } from 'formik';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import * as styles from './InputText.module.scss';
import MessageIcon from '@/assets/icons/envelope-fill.svg';

interface InputProps {
    name: string;
    [x: string]: any;
}

const InputText = ({ ...props }: InputProps) => {
    const [field, meta, helpers] = useField(props);

    return (
        <>
            <div className={styles.container}>
                <MessageIcon className={styles.icon} />
                <input className={styles.input} {...field} {...props} />
            </div>
            {meta.touched && meta.error && <ErrorMessage text={meta.error} />}
        </>
    );
};

export default InputText;
