import * as styles from './SendIncident.module.scss';
import { inputByType, sendIncidentSelectOptions } from './SendIncident.data';
import sensorsStylesSelect from '../Sensors/Sensors.styles';
import Select from 'react-select';
import IncidentHeader from '@/components/IncidentHeder/IncidentHeader';
import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import { MyModal } from '@/components/MyModal/MyModal';
import { toast } from 'react-toastify';
import SendIncidentcontroller from './SendIncident.controller';

const SendIncident = () => {
    const {
        deviceCode,
        sensor,
        setValueInput,
        setSensor,
        setOpenModal,
        valueInput,
        navigate,
        openModal,
        handleSubmitIncident
    } = SendIncidentcontroller();

    return (
        <>
            <div className={styles.container}>
                <IncidentHeader title="Sensor" secondTitle={deviceCode ? deviceCode : ''} />
                <div className={styles.content}>
                    <div className={styles.title}>Tipo de incidencia</div>
                    <Select
                        value={sensor}
                        options={sendIncidentSelectOptions}
                        styles={sensorsStylesSelect}
                        onChange={(value: any) => {
                            setValueInput('');
                            setSensor(value);
                        }}
                    />

                    <div className={styles.title}>{inputByType[sensor.value].label}</div>
                    {sensor.value === '0' ? (
                        <input
                            type="date"
                            className={styles.inputDate}
                            onChange={(e) => {
                                setValueInput(e.target.value);
                            }}
                        />
                    ) : (
                        (sensor.value === '1' || sensor.value === '2') && (
                            <textarea
                                className={styles.textArea}
                                placeholder="Escriba una breve descripción sobre la consulta"
                                onChange={(e) => {
                                    setValueInput(e.target.value);
                                }}
                            />
                        )
                    )}

                    <div className={styles.buttonsContainer}>
                        {sensor.value !== '-' && (
                            <div className={styles.buttons}>
                                <div className={styles.button}>
                                    <Button
                                        size={ButtonSizes.ADAPTABLE}
                                        variant={ButtonVariants.PRIMARY}
                                        onClick={() => {
                                            if (valueInput !== '') {
                                                setOpenModal(true);
                                            } else {
                                                toast.warning('No ha introducido ningún valor');
                                            }
                                        }}
                                    >
                                        Enviar incidencia
                                    </Button>
                                </div>
                                <div className={styles.button}>
                                    <Button
                                        size={ButtonSizes.ADAPTABLE}
                                        variant={ButtonVariants.PRIMARY_OUTLINE}
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        Volver atrás
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <MyModal
                isOpen={openModal}
                handleClose={() => {
                    setOpenModal(false);
                }}
                title={'Confirme la incidencia'}
            >
                <div className={styles.modalContent}>
                    <div className={styles.modalTexts}>
                        <div className={styles.modalText}>1. {sensor.label}</div>

                        <div className={styles.modalText}>2. {valueInput}</div>
                    </div>
                    <div className={styles.modalButtons}>
                        <div className={styles.modalButton}>
                            <Button
                                size={ButtonSizes.ADAPTABLE}
                                variant={ButtonVariants.PRIMARY}
                                onClick={handleSubmitIncident}
                            >
                                Enviar
                            </Button>
                        </div>
                        <div className={styles.modalButton}>
                            <Button
                                size={ButtonSizes.ADAPTABLE}
                                variant={ButtonVariants.PRIMARY_OUTLINE}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                Volver atrás
                            </Button>
                        </div>
                    </div>
                </div>
            </MyModal>
        </>
    );
};

export default SendIncident;
