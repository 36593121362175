import { useAppDispatch } from '@/index';
import { postIncidentsThunk } from '@/store/device/postIncidents.slice';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SelectOption } from '../Sensors/Sensors.type';

const SendIncidentcontroller = () => {
    const navigate = useNavigate();
    const appDispatch = useAppDispatch();
    const { id } = useParams();
    const { state }: any = useLocation();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [valueInput, setValueInput] = useState<string>('');
    const [sensor, setSensor] = useState<SelectOption>({
        value: '-',
        label: 'Selecciona el tipo de incidencia'
    });

    const deviceCode = state?.deviceCode;

    const handleSubmitIncident = () => {
        if (id) {
            appDispatch(
                postIncidentsThunk({
                    id: id,
                    type: sensor.value,
                    data: { date: valueInput, description: valueInput }
                })
            ).then(() => {
                navigate('/incidents');
            });
        }
        setOpenModal(false);
    };
    return {
        deviceCode,
        sensor,
        setValueInput,
        setSensor,
        setOpenModal,
        valueInput,
        navigate,
        openModal,
        handleSubmitIncident
    };
};

export default SendIncidentcontroller;
