.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    min-height: -webkit-fill-available;

    .nav {
        width: 100%;
        height: 35vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .logo {
            width: 300px;
        }

        .inamLogo {
            width: 300px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 14px;
            &__icon {
                width: 88px;
                height: 80px;
            }
            &__text {
                width: 140px;
                height: 120px;
            }
        }
    }
    .content {
        background-color: var(--color-primary);
        width: 100%;
        height: 65vh;
    }
}
