import * as styles from './Sensors.module.scss';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import sensorsStylesSelect from './Sensors.styles';
import { SelectOption } from './Sensors.type';
import IncidentHeader from '@/components/IncidentHeder/IncidentHeader';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@/store/rootReducer';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/index';
import { getDevicesThunk } from '@/store/device/getDevices.slice';

const Sensors = () => {
    const navigate = useNavigate();
    const devices = useSelector((state: RootState) => state.device.getDevices.devices);
    const appDispatch = useAppDispatch();
    const [sensor, setSensor] = useState<SelectOption>({
        value: -1,
        label: 'Selecciona un sensor'
    });
    const [sensorSelectOptions, setSensorSelectOptions] = useState<SelectOption[]>();

    useEffect(() => {
        if (devices.length > 0) {
            const sensorsOptions = devices.map((device) => {
                return { value: device.id, label: device.name };
            });
            setSensorSelectOptions(sensorsOptions);
        }
    }, [devices]);

    useEffect(() => {
        appDispatch(getDevicesThunk());
    }, []);

    return (
        <div className={styles.container}>
            <IncidentHeader title="Sensor" secondTitle="" />

            <div className={styles.content}>
                <Select
                    value={sensor}
                    options={sensorSelectOptions}
                    styles={sensorsStylesSelect}
                    onChange={(value: any) => {
                        const deviceCode = value.label.split('-');
                        navigate(`/incidents/sensors/${value.value}`, {
                            state: { deviceCode: deviceCode[1] }
                        });
                        setSensor(value);
                    }}
                />
            </div>
        </div>
    );
};

export default Sensors;
