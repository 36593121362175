import classNames from 'classnames';
import { useRef } from 'react';
import * as styles from './MyModal.module.scss';

interface MyModalProps {
    isOpen: boolean;
    handleClose: () => void;
    children: React.ReactNode;
    title: string;
}
export const MyModal = ({ isOpen, handleClose, children, title }: MyModalProps) => {
    const modalRef = useRef(null);
    window.onclick = (event) => {
        if (event.target === modalRef.current) {
            handleClose();
        }
    };
    return (
        <>
            <div
                id="myModal"
                className={classNames(
                    styles.modal,
                    styles[`modal${isOpen ? '__active' : '__inActive'}`]
                )}
                ref={modalRef}
            >
                <div className={styles.modalContent}>
                    <span className={styles.close} onClick={handleClose}>
                        &times;
                    </span>
                    <div className={styles.title}>{title}</div>
                    {children}
                </div>
            </div>
        </>
    );
};
