import { StylesConfig } from 'react-select';

const sensorsStylesSelect: StylesConfig = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? '1px solid var(--color-primary)' : `1px solid var(--color-gray);`,
        background: 'white',
        boxShadow: 'none',
        width: '100%',
        height: 42,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        color: '#777',
        '&:hover': {
            borderColor: 'var(--color-primary)'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? 'var(--color-primary)' : 'white',
        color: state.isFocused ? 'white' : '#333',
        borderColor: '#00b0f6;',
        height: 50,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: 'var(--color-primary)'
        }
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 'var(--z-level-3)'
    })
};
export default sensorsStylesSelect;
