.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .mapContainer {
        width: 100%;
        height: 100%;
    }
    .button {
        bottom: 60px;
        position: absolute;
        max-width: 400px;
        width: 70%;
        z-index: 1;
    }
}
