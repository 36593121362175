import IncidentHeader from '@/components/IncidentHeder/IncidentHeader';
import ScrollVertical from '@/components/ScrollVertical/ScrollVertical';
import OpenIncidentController from './OpenIncidents.controller';
import * as styles from './OpenIncidents.module.scss';

const OpenIncidents = () => {
    const { deviceCode, incidents, generateIncidentName } = OpenIncidentController();

    let openNumber: number = 0;
    let closeNumber: number = 0;

    return (
        <div className={styles.container}>
            <IncidentHeader title="Sensor" secondTitle={deviceCode ? deviceCode : ''} />
            <div className={styles.content}>
                <div className={styles.title}>
                    Incidencias <b>abiertas</b>
                </div>
                <div className={styles.scroll}>
                    <ScrollVertical>
                        {incidents &&
                            incidents.map((incident, i) => {
                                if (incident.status === 1) {
                                    openNumber++;
                                    return (
                                        <div className={styles.itemScroll} key={'open' + i}>
                                            <div className={styles.itemScroll__text}>
                                                {generateIncidentName({
                                                    i: openNumber,
                                                    type: incident.type,
                                                    date: incident.created_at
                                                })}
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                    </ScrollVertical>
                </div>
                <div className={styles.title}>
                    Incidencias <b>cerradas</b>
                </div>
                <div className={styles.scroll}>
                    <ScrollVertical>
                        {incidents &&
                            incidents.map((incident, i) => {
                                if (incident.status === 0) {
                                    closeNumber++;
                                    return (
                                        <div className={styles.itemScroll} key={'close' + i}>
                                            <div className={styles.itemScroll__text}>
                                                {generateIncidentName({
                                                    i: closeNumber,
                                                    type: incident.type,
                                                    date: incident.created_at
                                                })}
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                    </ScrollVertical>
                </div>
            </div>
        </div>
    );
};

export default OpenIncidents;
