.content {
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    .frame {
        background-color: transparent;
        width: 100%;
        height: 100%;
        border: none;
    }
}
