/*
 * DefaultProps object for different loaders
 */
var commonValues = {
    loading: true,
    color: "#000000",
    css: "",
    speedMultiplier: 1
};
export function sizeDefaults(sizeValue) {
    return Object.assign({}, commonValues, { size: sizeValue });
}
export function sizeMarginDefaults(sizeValue) {
    return Object.assign({}, sizeDefaults(sizeValue), {
        margin: 2
    });
}
export function heightWidthDefaults(height, width) {
    return Object.assign({}, commonValues, {
        height: height,
        width: width
    });
}
export function heightWidthRadiusDefaults(height, width, radius) {
    if (radius === void 0) { radius = 2; }
    return Object.assign({}, heightWidthDefaults(height, width), {
        radius: radius,
        margin: 2
    });
}
