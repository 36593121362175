import { useAppDispatch } from '@/index';
import { getIncidentsThunk } from '@/store/device/getIncidents.slice';
import { RootState } from '@/store/rootReducer';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { handleTypeIncidents } from './OpenIcidents.type';

const OpenIncidentController = () => {
    const { state }: any = useLocation();
    const { id } = useParams();
    const appDispatch = useAppDispatch();
    const incidents = useSelector((state: RootState) => state.device.getIncidents.incidents);

    const deviceCode = state?.deviceCode;

    const generateIncidentName = ({
        i,
        type,
        date
    }: {
        i: number;
        type: string | number;
        date: string;
    }) => {
        const dateObj: Date = new Date(date);

        const name =
            '# ' +
            i +
            ' - ' +
            handleTypeIncidents[type] +
            ' - ' +
            `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
        return name;
    };

    useEffect(() => {
        if (id) appDispatch(getIncidentsThunk({ id }));
    }, []);
    return { deviceCode, incidents, generateIncidentName };
};

export default OpenIncidentController;
