import { Error } from '@/models/Error';
import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface Device {
    id: string;
    latitud: number;
    longitud: number;
    name: string;
}
export interface GetDevicesState {
    error: Error | null;
    devices: Device[];
}
export const getDevicesThunk = createAsyncThunk<Device[], void, { rejectValue: Error }>(
    'device/getDevices',
    async (params, thunkApi) => {
        return request({
            url: '/api/devices',
            method: 'GET',
            extraHeaders: {
                Authorization: `Bearer ${accessToken.get()}`
            }
        })
            .then((res: ApiResponse) => {
                return res.data;
            })
            .catch((err: ApiResponse<Error>) => {
                return thunkApi.rejectWithValue(err.data);
            });
    }
);

export const getDevicesSlice = createSlice({
    name: 'devices',
    initialState: { error: null, devices: [] } as GetDevicesState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDevicesThunk.fulfilled, (state, { payload }) => {
            state.devices = payload;
        });
    }
});
