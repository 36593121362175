import { useEffect, useRef, useState } from 'react';
import * as L from 'leaflet';
import { MyModal } from '../MyModal/MyModal';
import Button from '../Button/Button';
import { ButtonSizes, ButtonVariants } from '../Button/Button.types';
import * as styles from './Map.module.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/rootReducer';
import { Device, getDevicesThunk } from '@/store/device/getDevices.slice';
import { useAppDispatch } from '@/index';

function Map() {
    const mapRef = useRef<any>(null);
    const navigate = useNavigate();
    const appDispatch = useAppDispatch();
    const devices = useSelector((state: RootState) => state.device.getDevices.devices);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [dataModal, setDataModal] = useState<{
        id: number | string;
        name: string;
        deviceCode?: string;
    }>({
        id: 0,
        name: '',
        deviceCode: ''
    });

    useEffect(() => {
        if (mapRef === null || mapRef.current === null) {
            try {
                mapRef.current = L.map('map', {
                    layers: [L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png')]
                }).setView([37.7, -0.999], 11);
            } catch (e) {
                // console.error(e);
            }
        }
        appDispatch(getDevicesThunk());
    }, []);
    useEffect(() => {
        if (mapRef.current && devices) {
            devices.map((m: Device) => {
                const latlng = L.latLng({ lat: m.latitud, lng: m.longitud });
                const marker = L.marker(latlng, {
                    title: m.name
                    //icon: InformationIcon,
                }).addTo(mapRef.current);
                marker.on('click', () => {
                    const deviceCode = m.name.split('-');
                    setOpenModal(true);
                    setDataModal({ id: m.id, name: m.name, deviceCode: deviceCode[1] });
                });
            });
        }
    }, [devices]);

    return (
        <>
            <div id="map" style={{ width: '100%', height: '100%', zIndex: 1 }} />
            <MyModal
                isOpen={openModal}
                handleClose={() => {
                    setOpenModal(false);
                }}
                title={dataModal.name}
            >
                <div className={styles.contentModal}>
                    <Button
                        size={ButtonSizes.ADAPTABLE}
                        variant={ButtonVariants.PRIMARY}
                        onClick={() => {
                            navigate(`/incidents/open-incidents/${dataModal.id}`, {
                                state: { deviceCode: dataModal.deviceCode }
                            });
                        }}
                    >
                        Incidencias
                    </Button>
                    <Button
                        size={ButtonSizes.ADAPTABLE}
                        variant={ButtonVariants.PRIMARY}
                        onClick={() => {
                            navigate(`/incidents/sensors/${dataModal.id}`, {
                                state: { deviceCode: dataModal.deviceCode }
                            });
                        }}
                    >
                        Nueva Incidencia
                    </Button>
                </div>
            </MyModal>
        </>
    );
}

export default Map;
