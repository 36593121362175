import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { deviceReducer } from './device';
import { loaderReducer } from './loader';
export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
    auth: authReducer,
    loader: loaderReducer,
    device: deviceReducer
});

export default rootReducer;
