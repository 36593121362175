.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 24px;
    background-color: white;
    justify-content: center;
    align-items: center;

    .input {
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        font-size: var(--font-medium);
        color: #777;
        margin: 10px;
    }
    .input::placeholder {
        color: var(--color-gray);
    }
    .input:focus {
        border: none;
        outline: none;
    }
    .icon {
        width: 30px;
        height: 30px;
        margin: 5px 0 5px 16px;
        color: var(--color-primary);
    }
}
