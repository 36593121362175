import { SelectOption } from '../Sensors/Sensors.type';

export const sendIncidentSelectOptions: SelectOption[] = [
    { value: '0', label: 'Solicitud de instalación / retirada' },
    { value: '1', label: 'Reporte de fallo' },
    { value: '2', label: 'Consulta' }
];

export const inputByType: any = {
    '-': { label: '', type: null },
    '0': { label: 'Selecciona un día', type: 'date' },
    '1': { label: 'Aporte una descripción', type: 'textArea' },
    '2': { label: 'Aporte una descripción', type: 'textArea' }
};
