import React from 'react';
import App from './App';
import './styles/index.scss';
import { createRoot } from 'react-dom/client';
import { makeServer } from './server';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './store/rootReducer';
import { Provider, useDispatch } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const store = configureStore({ reducer: rootReducer });
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

const container = document.getElementById('root');
const root = createRoot(container!);

// if (process.env.NODE_ENV === 'development') {
//     makeServer({ environment: 'development' });
// }

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

/* serviceWorkerRegistration.register({
    onUpdate: async (registration: any) => {
        // Corremos este código si hay una nueva versión de nuestra app
        // Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
        if (registration && registration.waiting) {
            await registration.unregister();
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            // Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
            window.location.reload();
        }
    }
}); */
