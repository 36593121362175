import { useAppDispatch } from '@/index';
import { loginThunk } from '@/store/auth/login.slice';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as styles from './Login.module.scss';
import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import InputText from '@/components/Input/InputText/InputText';
import InputPassword from '@/components/Input/InputPassword/InputPassword';
import { useEffect, useState } from 'react';
export interface LoginUser {
    email: string;
    password: string;
}

const loginSchema = Yup.object().shape({
    password: Yup.string(),
    email: Yup.string()
});
const Login = () => {
    const appDispatch = useAppDispatch();
    const navigate = useNavigate();

    const formSubmit = (values: LoginUser, actions: FormikHelpers<LoginUser>) => {
        appDispatch(loginThunk({ username: values.email, password: values.password })).then(
            (res) => {
                actions.setSubmitting(false);
                navigate('/');
            }
        );
    };

    const [isReadyForInstall, setIsReadyForInstall] = useState(false);

    async function downloadApp() {
        console.log('👍', 'butInstall-clicked'); //@ts-ignore
        const promptEvent = window.deferredPrompt;
        if (!promptEvent) {
            // The deferred prompt isn't available.
            console.log('oops, no prompt event guardado en window');
            return;
        }
        // Show the install prompt.
        promptEvent.prompt();
        // Log the result
        const result = await promptEvent.userChoice;
        console.log('👍', 'userChoice', result);
        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        //@ts-ignore
        window.deferredPrompt = null;
        // Hide the install button.
        setIsReadyForInstall(false);
    }
    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (event) => {
            // Prevent the mini-infobar from appearing on mobile.
            event.preventDefault();
            console.log('👍', 'beforeinstallprompt', event);
            // Stash the event so it can be triggered later.
            //@ts-ignore
            window.deferredPrompt = event;
            // Remove the 'hidden' class from the install button container.
            setIsReadyForInstall(true);
        });
    }, []);
    return (
        <div className={styles.container}>
            <Formik
                enableReinitialize
                validationSchema={loginSchema}
                initialValues={{ email: '', password: '' } as LoginUser}
                onSubmit={(values, actions) => {
                    formSubmit(values, actions);
                }}
                validateOnBlur={true}
                validateOnMount={true}
            >
                {(formik: FormikProps<LoginUser>) => (
                    <Form className={styles.content} onSubmit={formik.handleSubmit}>
                        <div className={styles.title}>Accede a tu cuenta</div>
                        <InputText name="email" type="email" placeholder="Email" />
                        <InputPassword name="password" placeholder="Contraseña" />
                        <div className={styles.containerButton}>
                            <Button
                                size={ButtonSizes.ADAPTABLE}
                                variant={ButtonVariants.SECONDARY}
                                type="submit"
                            >
                                Iniciar sesión
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            {isReadyForInstall && (
                <button type="button" onClick={downloadApp}>
                    Descargar
                </button>
            )}
        </div>
    );
};

export default Login;
