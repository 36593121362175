import Spinner from '../Spinner/Spinner';
import * as styles from './Loader.module.scss';
interface LoaderProps {
    isLoading: boolean;
}
const Loader = ({ isLoading }: LoaderProps) => {
    return (
        <div className={isLoading ? styles.container : ''}>
            <Spinner isLoading={isLoading} />
        </div>
    );
};

export default Loader;
