import { useField } from 'formik';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import * as styles from './InputPassword.module.scss';
import LockIcon from '@/assets/icons/lock-fill.svg';

interface InputProps {
    name: string;
    [x: string]: any;
}

const InputPassword = ({ ...props }: InputProps) => {
    const [field, meta, helpers] = useField(props);

    return (
        <>
            <div className={styles.container}>
                <LockIcon className={styles.icon} />
                <input className={styles.input} {...field} {...props} type={'password'} />
            </div>
            {meta.touched && meta.error && <ErrorMessage text={meta.error} />}
        </>
    );
};

export default InputPassword;
