import { combineReducers } from 'redux';
import { getDevicesSlice } from './getDevices.slice';
import { getIncidentsSlice } from './getIncidents.slice';
import { postIncidentsSlice } from './postIncidents.slice';

export const deviceReducer = combineReducers({
    getDevices: getDevicesSlice.reducer,
    getIncidents: getIncidentsSlice.reducer,
    postIncidets: postIncidentsSlice.reducer
});

export const devicesActions = {
    ...getDevicesSlice.actions,
    ...getIncidentsSlice.actions,
    ...postIncidentsSlice.actions
};
