.container {
    width: 100%;
    height: 100%;
    max-width: 900px;

    .content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin: 20px;
        gap: 10px;
        .title {
            color: var(--color-primary);
        }
        .scroll {
            width: 100%;
            height: 200px;
            border: 1px solid var(--color-gray);
            border-radius: 5px;
            overflow: hidden;
            .itemScroll {
                border-bottom: 2px solid var(--color-gray-low);
                &__text {
                    margin: 6px 10px 6px 10px;
                    color: #777;
                }
            }
        }
    }
}
