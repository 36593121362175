interface GuestUserLayoutProps {
    children?: React.ReactNode;
}
import * as styles from './GuestUserLayout.module.scss';
const GuestUserLayout = ({ children }: GuestUserLayoutProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.nav}>
                <img
                    className={styles.logo}
                    src={require('./../../assets/images/logo.png')}
                    alt=""
                />
                <div className={styles.inamLogo}>
                    <img
                        className={styles.inamLogo__icon}
                        src={require('./../../assets/images/inam-logo1.png')}
                    />
                    <img
                        className={styles.inamLogo__text}
                        src={require('./../../assets/images/inam-logo2.png')}
                    />
                </div>
            </div>
            <div className={styles.content}>{children}</div>
        </div>
    );
};

export default GuestUserLayout;
