const get = () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
        return refreshToken;
    }
    return null;
};

const set = (refreshToken: string) => {
    localStorage.setItem('refreshToken', refreshToken);
};

const remove = () => {
    localStorage.removeItem('refreshToken');
};

const exists = () => {
    return !!get();
};

const refreshToken = {
    get,
    set,
    remove,
    exists
};

export default refreshToken;
