import { Error } from '@/models/Error';
import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface Incident {
    id: string | number;
    type: number | string;
    device_id: string | number;
    device_name: string;
    latitude: number;
    longitude: number;
    status: number;
    user_id: string | number;
    resolution_date: string;
    data: {
        date: string;
        description: string;
    };
    created_at: string;
    updated_at: string;
}
export interface GetIncidentsState {
    error: Error | null;
    incidents: Incident[];
}
export const getIncidentsThunk = createAsyncThunk<
    Incident[],
    { id: string | number },
    { rejectValue: Error }
>('device/getIncidents', async (params, thunkApi) => {
    return request({
        url: `/api/devices/${params.id}/tickets`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    })
        .then((res: ApiResponse) => {
            return res.data.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getIncidentsSlice = createSlice({
    name: 'incidents',
    initialState: { error: null, incidents: [] } as GetIncidentsState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getIncidentsThunk.fulfilled, (state, { payload }) => {
            state.incidents = payload;
        });
    }
});
