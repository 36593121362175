import axios, {
    AxiosError,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosResponseHeaders,
    ResponseType
} from 'axios';
import accessToken from './accessToken.helper';
import refreshToken from './refreshToken.helper';

export interface ApiRequest<T = any> {
    url: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    data?: T;
    extraHeaders?: { [key: string]: string };
    params?: URLSearchParams;
    responseType?: ResponseType;
}

export interface ApiResponse<T = any> {
    data: T;
    status: number;
    statusText: string;
    headers: AxiosResponseHeaders;
}

const instance = axios.create({ baseURL: process.env.API_BASE_URL || '' });

const request = (call: ApiRequest): Promise<ApiResponse> => {
    return new Promise((resolve, reject) => {
        const onSuccess = (response: AxiosResponse) => {
            resolve({
                data: response.data,
                status: response.status,
                statusText: response.statusText,
                headers: response.headers
            });
        };

        const onError = (err: AxiosError) => {
            reject({
                data: err.response?.data,
                status: err.response?.status,
                statusText: err.response?.statusText,
                headers: err.response?.headers
            });

            if (err.response?.status === 401) {
                accessToken.remove();
                refreshToken.remove();
                const pathname = window.location.pathname;
                if (pathname !== '/login') {
                    location.reload();
                }
            }
        };

        const axiosCall: AxiosRequestConfig = {
            url: call.url,
            method: call.method,
            data: call.data,
            params: call.params,
            responseType: call.responseType,
            headers: {
                'Content-Type': 'application/json',
                ...call.extraHeaders
            }
        };

        instance(axiosCall).then(onSuccess).catch(onError);
    });
};

export default request;
