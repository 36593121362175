.container {
    width: 100%;
    max-width: 900px;
    height: 100%;
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .title {
            margin: 15px;
            font-size: large;
            display: flex;
            flex-direction: row;
            gap: 5px;
            .text {
                font-weight: bolder;
                color: var(--color-primary);
            }
            .number {
                color: var(--color-secondary);
            }
        }
        .return {
            background-color: var(--color-primary);
            border-radius: 50%;
            width: 40px;
            height: 40px;
            color: white;
            margin: 15px;
            .returnIcon {
                margin: 10px;
            }
        }
    }

    .content {
        margin: 15px;
    }
}
