import Loader from '@/components/Loader/Loader';
import { RootState } from '@/store/rootReducer';
import React from 'react';
import { useSelector } from 'react-redux';
import AppLayout from './AppLayout/AppLayout';
import GuestUserLayout from './GuestUserLayout/GuestUserLayout';

interface LayoutProps {
    children?: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    const isLoading = useSelector((state: RootState) => state.loader.setLoader.isLoading);
    const isAuthenticated = useSelector((state: RootState) => state.auth.login.isAuthenticated);

    return (
        <>
            <Loader isLoading={isLoading} />
            {isAuthenticated ? (
                <AppLayout>{children}</AppLayout>
            ) : (
                <GuestUserLayout>{children}</GuestUserLayout>
            )}
        </>
    );
};

export default Layout;
