import { useNavigate } from 'react-router-dom';
import * as styles from './IncidentHeader.module.scss';
import ReturnIcon from '@/assets/icons/reply-fill.svg';

interface IncidentHeaderProps {
    title: string;
    secondTitle?: string;
}

const IncidentHeader = ({ title, secondTitle }: IncidentHeaderProps) => {
    const navigate = useNavigate();

    return (
        <div className={styles.header}>
            <div className={styles.title}>
                <div className={styles.text}>{title}</div>
                <div className={styles.number}>{secondTitle}</div>
            </div>
            <div
                className={styles.return}
                onClick={() => {
                    navigate(-1);
                }}
            >
                <ReturnIcon className={styles.returnIcon} />
            </div>
        </div>
    );
};

export default IncidentHeader;
