.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 280px;
        gap: 25px;
        .title {
            font-size: 24px;
            color: white;
        }

        .containerButton {
            width: 70%;
            height: 46px;
        }
    }
}
