// import NavBar from '@/components/NavBar/NavBar';
import NavBar from '@/components/Navbar/NavBar';
import * as styles from './AppLayout.module.scss';
interface AppLayoutProps {
    children?: React.ReactNode;
}
const AppLayout = ({ children }: AppLayoutProps) => {
    return (
        <>
            <NavBar />
            <div className={styles.container}>{children}</div>
        </>
    );
};

export default AppLayout;
