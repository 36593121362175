import * as styles from './Home.module.scss';
import refreshToken from '@/utils/helpers/refreshToken.helper';
import accessToken from '@/utils/helpers/accessToken.helper';

const Home = () => {

    return (
        <div className={styles.content}>
            <iframe id="sensores" title="Sensores" src={`https://sensores.crcc.es/loginincidencias?jwt_token=${accessToken.get()}&refresh_token=${refreshToken.get()}`} className={styles.frame} />
        </div>
    );
};

export default Home;
