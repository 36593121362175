import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Layout from './layout/Layout';
import PrivateRoute from './layout/Routes/PrivateRoute';
import PublicRoute from './layout/Routes/PublicRoute';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Incidents from './pages/Incidents/Incidents';
import Sensors from './pages/Sensors/Sensors';
import SendIncident from './pages/SendIncident/SendIncident';
import OpenIncidents from './pages/OpenIncidents/OpenIncidents';
import LoginAPI from './pages/LoginAPI/LoginAPI';

// sensores.crcc.es/loginincidencias -> app.incidenciasensores.com/loginincidencias:
// 1) guardamos jwt_token y refresh_token en localStorage ->


const App = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <Home />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/incidents"
                        element={
                            <PrivateRoute>
                                <Incidents />
                            </PrivateRoute>
                        }
                    />
                    <Route path="/incidents/sensors">
                        <Route path="" element={<Sensors />} />
                        <Route path=":id" element={<SendIncident />} />
                    </Route>
                    <Route path="/incidents/open-incidents/:id" element={<OpenIncidents />} />
                    <Route
                        path="/login"
                        element={
                            <PublicRoute>
                                <Login />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <PrivateRoute>
                                <NotFound />
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </Layout>
            <ToastContainer
                position="top-right"
                limit={3}
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </BrowserRouter>
    );
};

export default App;
