.containerNav {
    width: 100%;
    height: var(--nav-height);
    .header {
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .containerLogo {
            display: flex;
            flex-direction: row;
            max-width: 600px;
            .logo {
                min-width: 80px;
                width: 100%;
                max-width: 300px;
                margin: 20px;
            }

            .inamLogo {
                min-width: 80px;
                width: 100%;
                max-width: 200px;

                min-height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                &__icon {
                    width: 40%;
                }
                &__text {
                    width: 60%;
                }
            }
        }
        .user {
            width: fit-content;
            color: var(--color-primary);
            margin: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            gap: 10px;
            cursor: pointer;
            .text {
                font-size: larger;
            }
            .icon {
                width: 30px;
            }
        }
    }
    .nav {
        width: 100%;
        height: 40%;
        background-color: var(--color-primary);
        display: flex;
        justify-content: space-around;
        justify-content: center;
        align-items: center;
        .option {
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(255, 255, 255, 0.76);
            font-size: 24px;
            background-color: transparent;
            border: none;

            &__active {
                color: white;
                font-weight: bolder;
                cursor: pointer;
            }
        }
        .option:hover {
            color: white;
            cursor: pointer;
        }

        .vl {
            border-left: 1px solid black;
            height: 70%;
        }
    }
}

.modalContent {
    margin-top: 20px;
    width: auto;
    &__icon {
        width: 30px;
        margin-right: 12px;
    }
}
