import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import Map from '@/components/Map/Map';
import { useNavigate } from 'react-router-dom';
import * as styles from './Incidents.module.scss';
const Incidents = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.container}>
            <div className={styles.mapContainer}>
                <Map />
            </div>
            <div className={styles.button}>
                <Button
                    variant={ButtonVariants.PRIMARY}
                    size={ButtonSizes.ADAPTABLE}
                    type={'button'}
                    onClick={() => {
                        navigate('/incidents/sensors');
                    }}
                >
                    + Nueva Incidencia
                </Button>
            </div>
        </div>
    );
};

export default Incidents;
