import request, { ApiRequest, ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Error } from '@/models/Error';
import accessToken from '@/utils/helpers/accessToken.helper';
import { toast } from 'react-toastify';
import refreshToken from '@/utils/helpers/refreshToken.helper';

const ErrorDiccionary: any = {
    401: 'Credenciales invalidas',
    404: 'Credenciales invalidas',
    500: 'Conexión fallida'
};

export const loginThunk = createAsyncThunk<
    { token: string },
    { username: string; password: string },
    { rejectValue: Error }
>('auth/login', async (params, thunkApi) => {
    return request({
        url: '/api/customers/login',
        method: 'POST',
        data: params
    } as ApiRequest<{ username: string; password: string }>)
        .then((res: ApiResponse<{ token: string; refreshToken: string }>) => {
            toast.success('Bienvenido');
            accessToken.set(res.data.token);
            refreshToken.set(res.data.refreshToken);
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            toast.error(ErrorDiccionary[err.status]);
            return thunkApi.rejectWithValue(err.data);
        });
});

type LoginState = {
    isLoading: boolean;
    error: Error | null;
    isAuthenticated: boolean;
};

export const authLoginSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: accessToken.exists(),
        isLoading: false,
        error: null
    } as LoginState,
    reducers: {
        logout: (state) => {
            state.isAuthenticated = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginThunk.fulfilled, (state) => {
            state.isLoading = false;
            state.error = null;
            state.isAuthenticated = true;
        });
        builder.addCase(loginThunk.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(loginThunk.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isAuthenticated = false;

            if (payload) {
                state.error = payload;
            }
        });
    }
});
